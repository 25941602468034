import React, { Fragment, useState, useEffect } from "react";
import HTTP from "../../../services/HTTP";
import { useParams, useNavigate } from "react-router-dom";

export default function ModulosAddUpdate() {
  const navigate = useNavigate();
  const params = useParams();

  //cuando cargue el componente evaluamos si hay un id

  useEffect(() => {
    if (params.id) {
      moduloId(params.id);
    }
  }, [params.id]);

  //estados para los formularios
  const [datos, setDatos] = useState({});

  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  //estado para saber si estamos editando o guardando
  const [edit, setEdit] = useState(false);

  //funcion para guardar los datos del formulario.
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (edit) {
        onUpdate();
      } else {
        const newModulo = {
          nombre: datos.modulo,
          esPrincipal: check1,
          principal: check2,
        };
        const postModulos = await HTTP.POST("modulo", newModulo);
        if (postModulos.status === 201) {
          navigate("/modulos");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onUpdate = async () => {
    try {
      const updateModulo = {
        nombre: datos.modulo,
        esPrincipal: 1,
        principal: 1,
      };

      const update = await HTTP.PUT(`modulo/${params.id}`, updateModulo);
      if (update.status === 200) {
        console.log("modulo updated successfully");
      } else if (update.status === 404) {
        console.log(update);
      }
    } catch (error) {
      return error.message;
    }
  };

  //consultamos un modulo por id
  const moduloId = async (id) => {
    const getById = await HTTP.GET(`modulo/${id}`);
    console.log(getById.data);
    setDatos(getById.data);
    setEdit(true);
  };

  //leer los datos del formulario
  const handleChanges = (e) => {
    setDatos({
      ...datos,
      [e.target.id]: e.target.value,
    });
  };

  const handleCheck = () => {
    setCheck1(!check1);
  };
  const handleCheck2 = () => {
    setCheck2(!check2);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-outline-info">
            <div className="card-header">
              <h4 className="m-b-0 text-white">
                {edit ? "Formulario para editar" : "Formularo para registrar"}
              </h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-body">
                  <h3 className="card-title">Informacion del modulos</h3>
                  <hr />
                  <div className="row p-t-20">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="control-label">
                          Nombre del modulo:
                        </label>
                        <input
                          type="text"
                          id="modulo"
                          className="form-control"
                          placeholder="Nombre del modulo..."
                          onChange={handleChanges}
                          defaultValue={datos.nombre}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mt-4">
                      <div className="form-group has-danger">
                        <div className="checkbox checkbox-success">
                          <input
                            id="checkbox1"
                            type="checkbox"
                            onChange={handleCheck}
                            checked={datos.esPrincipal}
                          />
                          <label for="checkbox1">Es principal</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-success">
                        <div className="checkbox checkbox-success">
                          <input
                            id="checkbox33"
                            type="checkbox"
                            checked={datos.principal}
                            onChange={handleCheck2}
                          />
                          <label htmlFor="checkbox33">principal</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions">
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-check"></i> Save
                  </button>
                  <button type="button" className="btn btn-inverse">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
