/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Encriptaciones from "../../../../services/Encriptaciones";
export default function Seguridad() {
  const permisos = Encriptaciones.getSession("modulos");

  return (
    <Fragment>
      {permisos.includes("ddd658fb-2b56-41ad-8282-a39cf3744523") && (
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "" : "has-arrow collapse")}
            to={"'javascript:void(0);'"}
            aria-expanded="false"
          >
            <i className="mdi mdi-gauge"></i>
            <span className="hide-menu">Seguridad</span>
          </NavLink>
          <ul aria-expanded="false" className="collapse">
            {permisos.includes("54d71f19-95a8-4a26-8be8-2c8b69f77df1") && (
              <li>
                <NavLink to="/modulos">Modulos</NavLink>
              </li>
            )}
            {permisos.includes("5a449ca9-ab15-4f57-bec2-28e883d8da08") && (
              <li>
                <NavLink to="/usuarios">Usuarios</NavLink>
              </li>
            )}
            {permisos.includes("f138801e-5b29-4335-b9d4-783a0c153459") && (
              <li>
                <NavLink to="/roles">Roles</NavLink>
              </li>
            )}
          </ul>
        </li>
      )}
      {permisos.includes("ab70daad-eda1-4474-a7fd-13fe216f393e") && (
        <li>
          <NavLink exact="true" to="/proyectos">
            <i className="mdi mdi-clipboard-flow" />
            <span className="hide-menu">Proyectos</span>
          </NavLink>
        </li>
      )}
    </Fragment>
  );
}
