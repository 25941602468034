import { Editor } from "@tinymce/tinymce-react";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";
// DOM model
import "tinymce/models/dom/model";
// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/directionality";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/image";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/preview";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";

// importing plugin resources
import "tinymce/plugins/emoticons/js/emojis";

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";

export default function BundledEditor(props) {
  const { init, ...rest } = props;
  // note that skin and content_css is disabled to avoid the normal
  // loading process and is instead loaded as a string via content_style
  tinymce.addI18n("es", {
    Redo: "Rehacer",
    Undo: "Deshacer",
    Cut: "Cortar",
    Copy: "Copiar",
    Paste: "Pegar",
    "Select all": "Seleccionar todo",
    "New document": "Nuevo documento",
    Ok: "Ok",
    Cancel: "Cancelar",
    "Visual aids": "Ayudas visuales",
    Bold: "Negrita",
    Italic: "Cursiva",
    Underline: "Subrayado",
    Strikethrough: "Tachado",
    Superscript: "Super\xedndice",
    Subscript: "Sub\xedndice",
    "Clear formatting": "Limpiar formato",
    Remove: "Quitar",
    "Align left": "Alinear a la izquierda",
    "Align center": "Alinear al centro",
    "Align right": "Alinear a la derecha",
    "No alignment": "Sin alineaci\xf3n",
    Justify: "Justificar",
    "Bullet list": "Lista de vi\xf1etas",
    "Numbered list": "Lista numerada",
    "Decrease indent": "Disminuir sangr\xeda",
    "Increase indent": "Incrementar sangr\xeda",
    Close: "Cerrar",
    Formats: "Formatos",
    "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
      "Su navegador no es compatible con el acceso directo al portapapeles. Use las teclas Crtl+X/C/V de su teclado.",
    Headings: "Encabezados",
    "Heading 1": "Encabezado 1",
    "Heading 2": "Encabezado 2",
    "Heading 3": "Encabezado 3",
    "Heading 4": "Encabezado 4",
    "Heading 5": "Encabezado 5",
    "Heading 6": "Encabezado 6",
    Preformatted: "Con formato previo",
    Div: "",
    Pre: "",
    Code: "C\xf3digo",
    Paragraph: "P\xe1rrafo",
    Blockquote: "Cita en bloque",
    Inline: "Alineado",
    Blocks: "Bloques",
    "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.":
      "Pegar est\xe1 ahora en modo de texto sin formato. El contenido se pegar\xe1 ahora como texto sin formato hasta que desactive esta opci\xf3n.",
    Fonts: "Fuentes",
    "Font sizes": "Tama\xf1os de fuente",
    Class: "Clase",
    "Browse for an image": "Buscar una imagen",
    OR: "",
    "Drop an image here": "Arrastre una imagen aqu\xed",
    Upload: "Cargar",
    "Uploading image": "Subiendo imagen",
    Block: "Bloque",
    Align: "Alinear",
    Default: "Por defecto",
    Circle: "C\xedrculo",
    Disc: "Disco",
    Square: "Cuadrado",
    "Lower Alpha": "Letras min\xfasculas",
    "Lower Greek": "Griego en min\xfasculas",
    "Lower Roman": "Romano en min\xfasculas",
    "Upper Alpha": "Letras may\xfasculas",
    "Upper Roman": "Romano en may\xfasculas",
    "Anchor...": "Anclaje...",
    Anchor: "",
    Name: "Nombre",
    ID: "",
    "ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.":
      "",
    "You have unsaved changes are you sure you want to navigate away?":
      "Tiene cambios sin guardar. \xbfEst\xe1 seguro de que quiere salir?",
    "Restore last draft": "Restaurar el \xfaltimo borrador",
    "Special character...": "Car\xe1cter especial...",
    "Special Character": "Car\xe1cter especial",
    "Source code": "C\xf3digo fuente",
    "Insert/Edit code sample": "Insertar/editar ejemplo de c\xf3digo",
    Language: "Idioma",
    "Code sample...": "Ejemplo de c\xf3digo...",
    "Left to right": "Izquierda a derecha",
    "Right to left": "Derecha a izquierda",
    Title: "T\xedtulo",
    Fullscreen: "Pantalla completa",
    Action: "Acci\xf3n",
    Shortcut: "Acceso directo",
    Help: "Ayuda",
    Address: "Direcci\xf3n",
    "Focus to menubar": "Enfocar la barra del men\xfa",
    "Focus to toolbar": "Enfocar la barra de herramientas",
    "Focus to element path": "Enfocar la ruta del elemento",
    "Focus to contextual toolbar":
      "Enfocar la barra de herramientas contextual",
    "Insert link (if link plugin activated)":
      "Insertar enlace (si el complemento de enlace est\xe1 activado)",
    "Save (if save plugin activated)":
      "Guardar (si el complemento de guardar est\xe1 activado)",
    "Find (if searchreplace plugin activated)":
      "Buscar (si el complemento buscar-reemplazar est\xe1 activado)",
    "Plugins installed ({0}):": "Complementos instalados ({0}):",
    "Premium plugins:": "Complementos premium:",
    "Learn more...": "M\xe1s informaci\xf3n...",
    "You are using {0}": "Est\xe1 usando {0}",
    Plugins: "Complementos",
    "Handy Shortcuts": "Accesos pr\xe1cticos",
    "Horizontal line": "L\xednea horizontal",
    "Insert/edit image": "Insertar/editar imagen",
    "Alternative description": "Descripci\xf3n alternativa",
    Accessibility: "Accesibilidad",
    "Image is decorative": "La imagen es decorativa",
    Source: "C\xf3digo fuente",
    Dimensions: "Dimensiones",
    "Constrain proportions": "Restringir proporciones",
    General: "General",
    Advanced: "Avanzado",
    Style: "Estilo",
    "Vertical space": "Espacio vertical",
    "Horizontal space": "Espacio horizontal",
    Border: "Borde",
    "Insert image": "Insertar imagen",
    "Image...": "Imagen...",
    "Image list": "Lista de im\xe1genes",
    Resize: "Redimensionar",
    "Insert date/time": "Insertar fecha/hora",
    "Date/time": "Fecha/hora",
    "Insert/edit link": "Insertar/editar enlace",
    "Text to display": "Texto que mostrar",
    Url: "URL",
    "Open link in...": "Abrir enlace en...",
    "Current window": "Ventana actual",
    None: "Ninguno",
    "New window": "Nueva ventana",
    "Open link": "Abrir enlace",
    "Remove link": "Quitar enlace",
    Anchors: "Anclajes",
    "Link...": "Enlace...",
    "Paste or type a link": "Pegue o escriba un enlace",
    "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?":
      "La URL que ha introducido parece ser una direcci\xf3n de correo electr\xf3nico. \xbfQuiere a\xf1adir el prefijo necesario mailto:?",
    "The URL you entered seems to be an external link. Do you want to add the required http:// prefix?":
      "La URL que ha introducido parece ser un enlace externo. \xbfQuiere a\xf1adir el prefijo necesario http://?",
    "The URL you entered seems to be an external link. Do you want to add the required https:// prefix?":
      "La URL que ha introducido parece ser un enlace externo. \xbfQuiere a\xf1adir el prefijo necesario https://?",
    "Link list": "Lista de enlaces",
    "Insert video": "Insertar v\xeddeo",
    "Insert/edit video": "Insertar/editar v\xeddeo",
    "Insert/edit media": "Insertar/editar medio",
    "Alternative source": "Enlace alternativo",
    "Alternative source URL": "Origen de URL alternativo",
    "Media poster (Image URL)": "P\xf3ster de medio (URL de imagen)",
    "Paste your embed code below:":
      "Pegue el c\xf3digo para insertar a continuaci\xf3n:",
    Embed: "Insertar",
    "Media...": "Medios...",
    "Nonbreaking space": "Espacio de no separaci\xf3n",
    "Page break": "Salto de p\xe1gina",
    "Paste as text": "Pegar como texto",
    Preview: "Previsualizar",
    Print: "Imprimir",
    "Print...": "Imprimir...",
    Save: "Guardar",
    Find: "Buscar",
    "Replace with": "Reemplazar por",
    Replace: "Reemplazar",
    "Replace all": "Reemplazar todo",
    Previous: "Anterior",
    Next: "Siguiente",
    "Find and Replace": "Buscar y Reemplazar",
    "Find and replace...": "Buscar y reemplazar...",
    "Could not find the specified string.":
      "No se encuentra la cadena especificada.",
    "Match case": "Coincidir may\xfasculas y min\xfasculas",
    "Find whole words only": "Solo palabras completas",
    "Find in selection": "Buscar en la selecci\xf3n",
    "Insert table": "Insertar tabla",
    "Table properties": "Propiedades de la tabla",
    "Delete table": "Eliminar tabla",
    Cell: "Celda",
    Row: "Fila",
    Column: "Columna",
    "Cell properties": "Propiedades de la celda",
    "Merge cells": "Combinar celdas",
    "Split cell": "Dividir celda",
    "Insert row before": "Insertar fila antes",
    "Insert row after": "Insertar fila despu\xe9s",
    "Delete row": "Eliminar fila",
    "Row properties": "Propiedades de la fila",
    "Cut row": "Cortar fila",
    "Cut column": "Cortar columna",
    "Copy row": "Copiar fila",
    "Copy column": "Copiar columna",
    "Paste row before": "Pegar la fila antes",
    "Paste column before": "Pegar columna antes",
    "Paste row after": "Pegar la fila despu\xe9s",
    "Paste column after": "Pegar columna despu\xe9s",
    "Insert column before": "Insertar columna antes",
    "Insert column after": "Insertar columna despu\xe9s",
    "Delete column": "Eliminar columna",
    Cols: "Columnas",
    Rows: "Filas",
    Width: "Ancho",
    Height: "Altura",
    "Cell spacing": "Espacio entre celdas",
    "Cell padding": "Relleno de celda",
    "Row clipboard actions": "",
    "Column clipboard actions": "",
    "Table styles": "Estilos de tabla",
    "Cell styles": "Estilos de celda",
    "Column header": "Encabezado de columna",
    "Row header": "Encabezado de fila",
    "Table caption": "T\xedtulo de la tabla",
    Caption: "Subt\xedtulo",
    "Show caption": "Mostrar t\xedtulo",
    Left: "Izquierda",
    Center: "Centro",
    Right: "Derecha",
    "Cell type": "Tipo de celda",
    Scope: "\xc1mbito",
    Alignment: "Alineaci\xf3n",
    "Horizontal align": "Alineaci\xf3n horizontal",
    "Vertical align": "Alineaci\xf3n vertical",
    Top: "Superior",
    Middle: "Central",
    Bottom: "Inferior",
    "Header cell": "Celda de encabezado",
    "Row group": "Grupo de filas",
    "Column group": "Grupo de columnas",
    "Row type": "Tipo de fila",
    Header: "Encabezado",
    Body: "Cuerpo",
    Footer: "Pie de p\xe1gina",
    "Border color": "Color de borde",
    Solid: "",
    Dotted: "",
    Dashed: "",
    Double: "",
    Groove: "",
    Ridge: "",
    Inset: "",
    Outset: "",
    Hidden: "",
    "Insert template...": "Insertar plantilla...",
    Templates: "Plantillas",
    Template: "Plantilla",
    "Insert Template": "Insertar Plantilla",
    "Text color": "Color del texto",
    "Background color": "Color de fondo",
    "Custom...": "Personalizado...",
    "Custom color": "Color personalizado",
    "No color": "Sin color",
    "Remove color": "Quitar color",
    "Show blocks": "Mostrar bloques",
    "Show invisible characters": "Mostrar caracteres invisibles",
    "Word count": "Contar palabras",
    Count: "Recuento",
    Document: "Documento",
    Selection: "Selecci\xf3n",
    Words: "Palabras",
    "Words: {0}": "Palabras: {0}",
    "{0} words": "{0} palabras",
    File: "Archivo",
    Edit: "Editar",
    Insert: "Insertar",
    View: "Ver",
    Format: "Formato",
    Table: "Tabla",
    Tools: "Herramientas",
    "Powered by {0}": "Con tecnolog\xeda de {0}",
    "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help":
      "\xc1rea de texto enriquecido. Pulse ALT-F9 para el men\xfa. Pulse ALT-F10 para la barra de herramientas. Pulse ALT-0 para la ayuda.",
    "Image title": "Titulo de imagen",
    "Border width": "Ancho de borde",
    "Border style": "Estilo de borde",
    Error: "Error",
    Warn: "Advertencia",
    Valid: "V\xe1lido",
    "To open the popup, press Shift+Enter":
      "Para abrir el elemento emergente, pulse May\xfas+Intro",
    "Rich Text Area": "\xc1rea de Texto Enriquecido",
    "Rich Text Area. Press ALT-0 for help.":
      "\xc1rea de texto enriquecido. Pulse ALT-0 para abrir la ayuda.",
    "System Font": "Fuente de sistema",
    "Failed to upload image: {0}": "Fallo al cargar imagen: {0}",
    "Failed to load plugin: {0} from url {1}":
      "Fallo al cargar complemento: {0} desde URL {1}",
    "Failed to load plugin url: {0}":
      "Fallo al cargar URL del complemento: {0}",
    "Failed to initialize plugin: {0}": "Fallo al iniciar el complemento: {0}",
    example: "ejemplo",
    Search: "Buscar",
    All: "Todo",
    Currency: "Divisa",
    Text: "Texto",
    Quotations: "Comillas",
    Mathematical: "S\xedmbolo matem\xe1tico",
    "Extended Latin": "Latino extendido A",
    Symbols: "S\xedmbolos",
    Arrows: "Flechas",
    "User Defined": "Definido por el usuario",
    "dollar sign": "signo de d\xf3lar",
    "currency sign": "signo de divisa",
    "euro-currency sign": "signo de euro",
    "colon sign": "signo de dos puntos",
    "cruzeiro sign": "signo de cruceiro",
    "french franc sign": "signo de franco franc\xe9s",
    "lira sign": "signo de lira",
    "mill sign": "signo de mill",
    "naira sign": "signo de naira",
    "peseta sign": "signo de peseta",
    "rupee sign": "signo de rupia",
    "won sign": "signo de won",
    "new sheqel sign": "signo de nuevo s\xe9quel",
    "dong sign": "signo de dong",
    "kip sign": "signo de kip",
    "tugrik sign": "signo de tugrik",
    "drachma sign": "signo de dracma",
    "german penny symbol": "signo de penique alem\xe1n",
    "peso sign": "signo de peso",
    "guarani sign": "signo de guaran\xed",
    "austral sign": "signo de austral",
    "hryvnia sign": "signo de grivna",
    "cedi sign": "signo de cedi",
    "livre tournois sign": "signo de libra tornesa",
    "spesmilo sign": "signo de spesmilo",
    "tenge sign": "signo de tenge",
    "indian rupee sign": "signo de rupia india",
    "turkish lira sign": "signo de lira turca",
    "nordic mark sign": "signo de marco n\xf3rdico",
    "manat sign": "signo de manat",
    "ruble sign": "signo de rublo",
    "yen character": "car\xe1cter de yen",
    "yuan character": "car\xe1cter de yuan",
    "yuan character, in hong kong and taiwan":
      "car\xe1cter de yuan en Hong Kong y Taiw\xe1n",
    "yen/yuan character variant one": "Variante uno de car\xe1cter de yen/yuan",
    Emojis: "Emojis",
    "Emojis...": "Emojis...",
    "Loading emojis...": "Cargando emojis...",
    "Could not load emojis": "No se pudieron cargar los emojis",
    People: "Personas",
    "Animals and Nature": "Animales y naturaleza",
    "Food and Drink": "Comida y bebida",
    Activity: "Actividad",
    "Travel and Places": "Viajes y lugares",
    Objects: "Objetos",
    Flags: "Banderas",
    Characters: "Caracteres",
    "Characters (no spaces)": "Caracteres (sin espacios)",
    "{0} characters": "{0} caracteres",
    "Error: Form submit field collision.":
      "Error: Colisi\xf3n de campo al enviar formulario.",
    "Error: No form element found.":
      "Error: No se encuentra ning\xfan elemento de formulario.",
    "Color swatch": "Muestrario de colores",
    "Color Picker": "Selector de colores",
    "Invalid hex color code: {0}": "",
    "Invalid input": "Entrada inv\xe1lida",
    R: "",
    "Red component": "Componente rojo",
    G: "",
    "Green component": "Componente verde",
    B: "",
    "Blue component": "Componente azul",
    "#": "",
    "Hex color code": "C\xf3digo de color hexadecimal",
    "Range 0 to 255": "Rango de 0 a 255",
    Turquoise: "Turquesa",
    Green: "Verde",
    Blue: "Azul",
    Purple: "P\xfarpura",
    "Navy Blue": "Azul marino",
    "Dark Turquoise": "Turquesa oscuro",
    "Dark Green": "Verde oscuro",
    "Medium Blue": "Azul medio",
    "Medium Purple": "P\xfarpura medio",
    "Midnight Blue": "Azul medio",
    Yellow: "Amarillo",
    Orange: "Naranja",
    Red: "Rojo",
    "Light Gray": "Gris claro",
    Gray: "Gris",
    "Dark Yellow": "Amarillo oscuro",
    "Dark Orange": "Naranja oscuro",
    "Dark Red": "Rojo oscuro",
    "Medium Gray": "Gris medio",
    "Dark Gray": "Gris oscuro",
    "Light Green": "Verde claro",
    "Light Yellow": "Amarillo claro",
    "Light Red": "Rojo claro",
    "Light Purple": "Morado claro",
    "Light Blue": "Azul claro",
    "Dark Purple": "Morado oscuro",
    "Dark Blue": "Azul oscuro",
    Black: "Negro",
    White: "Blanco",
    "Switch to or from fullscreen mode":
      "Activar o desactivar modo pantalla completa",
    "Open help dialog": "Abrir di\xe1logo de ayuda",
    history: "historial",
    styles: "estilos",
    formatting: "formato",
    alignment: "alineaci\xf3n",
    indentation: "sangr\xeda",
    Font: "Fuente",
    Size: "Tama\xf1o",
    "More...": "M\xe1s...",
    "Select...": "Seleccionar...",
    Preferences: "Preferencias",
    Yes: "S\xed",
    No: "No",
    "Keyboard Navigation": "Navegaci\xf3n con el teclado",
    Version: "Versi\xf3n",
    "Code view": "Vista de c\xf3digo",
    "Open popup menu for split buttons": "",
    "List Properties": "Propiedades de Lista",
    "List properties...": "Propiedades de Lista...",
    "Start list at number": "Iniciar lista con un n\xfamero",
    "Line height": "Altura de l\xednea",
    "Dropped file type is not supported": "",
    "Loading...": "Cargando...",
    "ImageProxy HTTP error: Rejected request": "",
    "ImageProxy HTTP error: Could not find Image Proxy": "",
    "ImageProxy HTTP error: Incorrect Image Proxy URL": "",
    "ImageProxy HTTP error: Unknown ImageProxy error": "",
  });
  return (
    <Editor
      init={{
        ...init,
        skin: false,
        content_css: false,
        content_style: [
          contentCss,
          contentUiCss,
          init.content_style || "",
        ].join("\n"),
      }}
      {...rest}
    />
  );
}
