import React from "react";
import Menu from "../menu/Menu";
import Encriptaciones from "../../../services/Encriptaciones";
function SideBar() {
  return (
    <aside className="left-sidebar">
      <div className="scroll-sidebar">
        <div
          className="user-profile"
          style={{
            background: `url(${process.env.PUBLIC_URL}/assets/images/background/user-info.jpg) no-repeat`,
          }}
        >
          <div className="profile-img">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/users/userdefault.png`}
              alt="user"
            />
          </div>
          <div className="text-center">
            <span className="label label-info">
              {Encriptaciones.getSession("dataUser").alias}
            </span>
          </div>
        </div>

        <Menu />

        {/* End Sidebar navigation */}
      </div>
      {/* End Sidebar scroll*/}
      {/* Bottom points*/}
      <div className="sidebar-footer">
        {/* item*/}
        <a href="true" className="link" data-toggle="tooltip" title="Settings">
          {/* <i className="ti-settings" /> */}
        </a>
        {/* item*/}
        <a href="true" className="link" data-toggle="tooltip" title="Email">
          {/* <i className="mdi mdi-gmail" /> */}
        </a>
        {/* item*/}
        <a href="true" className="link" data-toggle="tooltip" title="Logout">
          {/* <i className="mdi mdi-power" /> */}
        </a>
      </div>
      {/* End Bottom points*/}
    </aside>
  );
}

export default SideBar;
