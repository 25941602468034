import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LayoutPanel from "../../../layout/LayoutPanel";
import HTTP from "../../../services/HTTP";
import Alert from "../../../services/Alert";
import { useForm } from "react-hook-form";

export default function AddUsuariosUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [formulario, setFormulario] = useState({
    nombre: "",
    usuario: "",
    alias: "",
    correo: "",
    cargo: "",
    rol: "",
  });

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (id) {
      handleUserLoad(id);
    }
    handleRoles();
    // eslint-disable-next-line
  }, [id]);

  const onSubmit = (dataUser) => {
    if (id) {
      const update = {
        nombre: dataUser.nombre,
        usuario: dataUser.usuario,
        alias: dataUser.alias,
        correo: dataUser.correo,
        cargo: parseInt(dataUser.cargo),
        rol: parseInt(dataUser.rol),
      };
      Alert.loading_reload(true, "Actualizando Usuaruio");
      HTTP.PUT(`usuarios/${id}`, update).then((result) => {
        Alert.loading_reload(false);
        if (result !== false) {
          console.log(result);
          if (result.status === 200) {
            Alert.alertEmpty(
              "Usuaruio actualizado con éxito!",
              "Administración de proyectos",
              "success"
            );
            navigate("/usuarios");
          }
        }
      });
    } else {
      let data = {
        nombre: dataUser.nombre,
        usuario: dataUser.usuario,
        alias: dataUser.alias,
        correo: dataUser.correo,
        cargo: parseInt(dataUser.cargo),
        rol: parseInt(dataUser.rol),
      };
      Alert.loading_reload(true, "Guardando Usuario");
      HTTP.POST("usuarios", data).then((result) => {
        Alert.loading_reload(false);

        if (result !== false) {
          if (result.status === 201) {
            Alert.alertEmpty(
              "Usuario guardado con éxito!",
              "Administración de proyectos",
              "success"
            );
            navigate("/usuarios");
          }
        }
      });
    }
  };

  const handleChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    setFormulario({ ...formulario, [idComponente]: valorComponente });
  };

  const handleRoles = async (e) => {
    try {
      const apiRol = await HTTP.GET("/rol");
      setRoles(apiRol.data);
    } catch (error) {
      return error;
    }
  };

  const handleUserLoad = async (id) => {
    try {
      const user = await HTTP.GET(`/usuarios/${id}`);
      setFormulario(user.data);
      setValue("nombre", user.data.nombre);
      setValue("usuario", user.data.usuario);
      setValue("alias", user.data.alias);
      setValue("correo", user.data.correo);
      setValue("cargo", user.data.cargo.id);
      setValue("rol", user.data.rol.id);
    } catch (error) {
      return error;
    }
  };

  const options = [
    { value: 1, label: "Editor" },
    { value: 2, label: "Administrador" },
  ];

  return (
    <Fragment>
      <LayoutPanel titulo={"Registro de Usuarios"}>
        <div className="card">
          <div className="card-header">
            <h4 className="m-b-0 text-white">
              Formulario para registrar un usuario
            </h4>
          </div>
          <div className="card-body">
            <form>
              <div className="form-body">
                <h3 className="card-title">Informacion personal</h3>
                <hr />
                <div className="row p-t-20">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="control-label">Nombre completo</label>
                      <input
                        type="text"
                        id="nombre"
                        className="form-control"
                        {...register("nombre", {
                          required: true,
                        })}
                        placeholder="John doe"
                        onChange={handleChange}
                        defaultValue={formulario.nombre}
                      />
                      {errors.nombre?.type === "required" && (
                        <b style={{ color: "#E77575" }} className="mt-2">
                          Nombre es requerido
                        </b>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="control-label">Nombre de usuario</label>
                      <input
                        type="text"
                        id="usuario"
                        className="form-control"
                        {...register("usuario", {
                          required: true,
                        })}
                        placeholder="Nombre de usuario"
                        onChange={handleChange}
                        defaultValue={formulario.usuario}
                      />
                      {errors.usuario?.type === "required" && (
                        <b style={{ color: "#E77575" }} className="mt-2">
                          Usuario es requerido
                        </b>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="control-label">Alias</label>
                      <input
                        type="text"
                        id="alias"
                        className="form-control"
                        {...register("alias", {
                          required: true,
                        })}
                        placeholder="Alias"
                        onChange={handleChange}
                        defaultValue={formulario.alias}
                      />
                      {errors.alias?.type === "required" && (
                        <b style={{ color: "#E77575" }} className="mt-2">
                          Alias es requerido
                        </b>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="control-label">
                        Correo electronico
                      </label>
                      <input
                        type="text"
                        id="correo"
                        className="form-control"
                        {...register("correo", {
                          required: true,
                        })}
                        placeholder="correo@correo.com"
                        onChange={handleChange}
                        defaultValue={formulario.correo}
                      />
                      {errors.correo?.type === "required" && (
                        <b style={{ color: "#E77575" }} className="mt-2">
                          Correo es requerido
                        </b>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 form-group">
                    <label htmlFor="">Rol:</label>
                    <select
                      id="rol"
                      onChange={handleChange}
                      {...register("rol", {
                        required: true,
                      })}
                      defaultValue={
                        formulario.rol?.id
                          ? String(formulario.rol?.id)
                          : String(formulario.rol)
                      }
                      className="form-control"
                    >
                      <option value="" disabled={true}>
                        Seleccione una opción
                      </option>
                      {roles.map((roles) => (
                        <option key={roles.id} value={roles.id}>
                          {roles.nombre}
                        </option>
                      ))}
                    </select>
                    {errors.rol?.type === "required" && (
                      <b style={{ color: "#E77575" }} className="mt-2">
                        Rol es requerido
                      </b>
                    )}
                  </div>

                  <div className="col-sm-6 form-group">
                    <label className="control-label">
                      Seleccione una categoria
                    </label>
                    <div className="m-b-10">
                      <select
                        onChange={handleChange}
                        className="form-control"
                        name="cargo"
                        id="cargo"
                        {...register("cargo", {
                          required: true,
                        })}
                        defaultValue={
                          formulario.cargo?.id
                            ? String(formulario.cargo?.id)
                            : String(formulario.cargo)
                        }
                      >
                        <option value="" disabled={true}>
                          Seleccione una opción
                        </option>
                        {options.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      {errors.cargo?.type === "required" && (
                        <b style={{ color: "#E77575" }} className="mt-2">
                          Cargo es requerido
                        </b>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer text-center">
            <button
              className="btn btn-secondary btn-lg mr-2"
              onClick={() => {
                navigate("/usuarios");
              }}
            >
              <i className="fa fa-arrow-circle-left"></i> SALIR
            </button>
            <button
              className="btn btn-info btn-lg"
              onClick={handleSubmit(onSubmit.bind(this))}
            >
              <i className="fa fa-save"></i> Guardar
            </button>
          </div>
        </div>
      </LayoutPanel>
    </Fragment>
  );
}
