import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
//import Home from "./pages/home/Home.jsx";
import Modulos from "./pages/seguridad/modulos/Modulos";
import LayoutPrincipal from "./layout/LayoutPrincipal";
import ModulosAddUpdate from "./pages/seguridad/modulos/ModulosAddUpdate.jsx";
import Roles from "./pages/seguridad/roles/Roles.jsx";
import RolesAddUpdate from "./pages/seguridad/roles/RolesAddUpdate.jsx";
import Usuarios from "./pages/seguridad/usuarios/Usuarios.jsx";
import AddUsuariosUpdate from "./pages/seguridad/usuarios/AddUsuariosUpdate.jsx";
import PrivateRoute from "./routes/PrivateRoute.jsx";
import Login from "./pages/Login/Login.jsx";
import ProyectosForm from "./pages/proyectos/ProyectosForm.jsx";
import "./App.css";
import Proyectos from "./pages/proyectos/Proyectos.jsx";
function App() {
  return (
    <Router>
      <LayoutPrincipal>
        <Routes>
          <Route exact path="/login" element={<Login />} />

          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<Navigate to="/proyectos" />} />
          </Route>
          <Route exact path="/modulos" element={<Modulos />} />
          <Route exact path="/modulos/add" element={<ModulosAddUpdate />} />
          <Route
            exact
            path="/modulos/update/:id"
            element={<ModulosAddUpdate />}
          />
          <Route exact path="/roles" element={<Roles />} />
          <Route exact path="/roles/add" element={<RolesAddUpdate />} />
          <Route exact path="/roles/update/:id" element={<RolesAddUpdate />} />
          <Route exact path="/usuarios" element={<Usuarios />} />
          <Route exact path="/usuarios/add" element={<AddUsuariosUpdate />} />
          <Route exact path="/usuarios/update/:id" element={<AddUsuariosUpdate />} />

          <Route exact path="/proyectos" element={<Proyectos />} />
          <Route exact path="/proyectos/create" element={<ProyectosForm />} />
          <Route
            exact
            path="/proyectos/update/:id"
            element={<ProyectosForm />}
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </LayoutPrincipal>
    </Router>
  );
}

export default App;
