import React, { Fragment } from "react";
import Encriptaciones from "../../../services/Encriptaciones";
import SideBar from "./SideBar";

const Header = () => {
  const logout = () => {
    Encriptaciones.clearSession();
    window.location.href = "/login";
  };
  const voidHref = null;

  return (
    <Fragment>
      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          {/* ============================================================== */}
          {/* Logo */}
          {/* ============================================================== */}
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              {/* Logo icon */}
              <b>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-icon.png`}
                  alt="homepage"
                  className="dark-logo"
                />
                {/* Light Logo icon */}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-peof.png`}
                  height="40"
                  width={"40"}
                  alt="homepage"
                  className="light-logo"
                />
              </b>
              {/*End Logo icon */}
              {/* Logo text */}
            </a>
          </div>
          {/* ============================================================== */}
          {/* End Logo */}
          {/* ============================================================== */}
          <div className="navbar-collapse">
            {/* ============================================================== */}
            {/* toggle and nav items */}
            {/* ============================================================== */}
            <ul className="navbar-nav mr-auto mt-md-0">
              {/* This is  */}
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
                  href={voidHref}
                >
                  <i className="mdi mdi-menu" />
                </a>{" "}
              </li>
              <li className="nav-item m-l-10">
                {" "}
                <a
                  className="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark"
                  href={voidHref}
                >
                  <i className="ti-menu" />
                </a>{" "}
              </li>
              {/* ============================================================== */}
            </ul>
            {/* ============================================================== */}
            {/* User profile and search */}
            {/* ============================================================== */}
            <ul className="navbar-nav my-lg-0">
              {/* ============================================================== */}
              {/* Profile */}
              {/* ============================================================== */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  href={voidHref}
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/users/userdefault.png`}
                    alt="user"
                    className="profile-pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right scale-up">
                  <ul className="dropdown-user">
                    <li>
                      <div className="dw-user-box">
                        <div className="u-img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/users/userdefault.png`}
                            alt="user"
                          />
                        </div>
                        <div className="u-text">
                          <h4>{Encriptaciones.getSession("dataUser").alias}</h4>
                          <p className="text-muted">
                            {Encriptaciones.getSession("dataUser").correo}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li role="separator" className="divider" />
                    {/* <li>
                      <a href={voidHref}>
                        <i className="ti-user" /> My Profile
                      </a>
                    </li>

                    <li role="separator" className="divider" /> */}

                    <li>
                      <button
                        className="btn btn-link"
                        onClick={logout.bind(this)}
                      >
                        <i className="fa fa-power-off" /> Cerrar Sesión
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <SideBar />
    </Fragment>
  );
};

export default Header;
