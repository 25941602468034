import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BundledEditor from "../../components/BundleEditor";
import LayoutPanel from "../../layout/LayoutPanel";
import Alert from "../../services/Alert";
import Extension from "../../services/Extension";
import HTTP from "../../services/HTTP";
import { useForm } from "react-hook-form";

const ProyectosForm = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [proyecto, setProyecto] = useState({
    sitio: "",
    tipo: "",
    tituloEs: "",
    tituloEn: "",
    descripcionEs: "",
    descripcionEn: "",
    detalleEn: "",
    detalleEs: "",
    imagen: null,
  });

  const [detalle, setDetalle] = useState([]);

  const editorRefEn = useRef(null);
  const editorRefEs = useRef(null);

  useEffect(() => {
    if (id) {
      verifyParams(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const onSubmit = (dataNoticia) => {
    if (id) {
      const data = {
        sitio: dataNoticia.sitio,
        tipo: dataNoticia.tipo,
        tituloEs: proyecto.tituloEs,
        tituloEn: proyecto.tituloEn,
        descripcionEn: proyecto.descripcionEn,
        descripcionEs: proyecto.descripcionEs,
        contenidoEs: editorRefEn.current.getContent(),
        contenidoEn: editorRefEs.current.getContent(),
      };
      Alert.loading_reload(true, "Actualizando Proyecto");
      HTTP.PUT(`noticias/${id}/${detalle.id}`, data).then((result) => {
        Alert.loading_reload(false);
        if (result !== false) {
          console.log(result);
          if (result.status === 200) {
            Alert.alertEmpty(
              "¡Proyecto actualizado con éxito!",
              "Administración de proyectos",
              "success"
            );
            navigate("/proyectos");
          }
        }
      });
    } else {
      const data = new FormData();
      data.append("imagen", proyecto.imagen, proyecto.imagen?.name);
      data.append("sitio", dataNoticia.sitio);
      data.append("tipo", dataNoticia.tipo);
      data.append("tituloEs", dataNoticia.tituloEs);
      data.append("tituloEn", dataNoticia.tituloEn);
      data.append("descripcionEs", dataNoticia.descripcionEs);
      data.append("descripcionEn", dataNoticia.descripcionEn);
      data.append("contenidoEs", editorRefEs.current.getContent());
      data.append("contenidoEn", editorRefEn.current.getContent());
      Alert.loading_reload(true, "Guardando Proyecto");
      console.log(data);
      HTTP.POST_FILE("noticias", data).then((result) => {
        Alert.loading_reload(false);

        if (result !== false) {
          if (result.status === 201) {
            Alert.alertEmpty(
              "¡Proyecto guardado con éxito!",
              "Administración de proyectos",
              "success"
            );
            navigate("/proyectos");
          }
        }
      });
    }
  };

  const [edit, setEdit] = useState(false);

  const sitios = [
    // {
    //   id: 1,
    //   nombre: "Amunshea",
    // },
    {
      id: 2,
      nombre: "Peof",
    },
    // {
    //   id: 3,
    //   nombre: "Ambos",
    // },
  ];

  // const log = () => {
  //   if (editorRefEn.current) {
  //     console.log(editorRefEn.current.getContent());
  //   }
  // };
  const handleChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    console.log(valorComponente);
    setProyecto({ ...proyecto, [idComponente]: valorComponente });
  };

  const verifyParams = async (id) => {
    const getById = await HTTP.GET(`noticias/${id}`);
    const data = getById.data;
    setEdit(true);
    setProyecto(getById.data);
    setDetalle(getById.data.detalleNoticia);
    setValue("tituloEs", data.tituloEs);
    setValue("tituloEn", data.tituloEn);
    setValue("sitio", data.sitio?.id);
    setValue("tipo", data.tipo);
    setValue("descripcionEn", data.descripcionEn);
    setValue("descripcionEs", data.descripcionEs);
  };

  const onImagenChange = (e) => {
    let name = e.target.files[0].name;
    if (Extension.isImage(name)) {
      setProyecto({ ...proyecto, imagen: e.target.files[0] });
    } else {
      Alert.alertEmpty(
        "¡El archivo seleccionado no es válido!",
        "Solo se permiten archivos PNG, JPG, JPEG",
        "warning"
      );
      e.target.value = null;
    }
  };

  return (
    <React.Fragment>
      <LayoutPanel titulo="Registro de Proyectos y Noticias de Sitios Web">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Datos de Proyecto:</h4>
            <div className="row">
              <div className="col-lg-3 form-group">
                <label htmlFor="">Título Español:</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("tituloEs", {
                    required: true,
                  })}
                  id="tituloEs"
                  defaultValue={proyecto.tituloEs}
                  onChange={handleChange}
                  placeholder="Título Español"
                />
                {errors.tituloEs?.type === "required" && (
                  <b style={{ color: "#E77575" }} className="mt-2">
                    Título Español es requerido
                  </b>
                )}
              </div>
              <div className="col-lg-3 form-group">
                <label htmlFor="">Título Inglés:</label>
                <input
                  type="text"
                  className="form-control"
                  id="tituloEn"
                  {...register("tituloEn", {
                    required: true,
                  })}
                  onChange={handleChange}
                  defaultValue={proyecto.tituloEn}
                  placeholder="Escriba título ingles"
                />
                {errors.tituloEn?.type === "required" && (
                  <b style={{ color: "#E77575" }} className="mt-2">
                    Título Inglés es requerido
                  </b>
                )}
              </div>
              <div className="col-lg-3 form-group">
                <label htmlFor="">Sitio:</label>
                <select
                  onChange={handleChange}
                  id="sitio"
                  defaultValue={
                    proyecto.sitio?.id
                      ? String(proyecto.sitio?.id)
                      : String(proyecto.sitio)
                  }
                  {...register("sitio", {
                    required: true,
                  })}
                  className="form-control"
                >
                  <option value="" disabled={true}>
                    Seleccione una opción
                  </option>
                  {sitios.map((sitio) => (
                    <option key={sitio.id} value={sitio.id}>
                      {sitio.nombre}
                    </option>
                  ))}
                </select>
                {errors.sitio?.type === "required" && (
                  <b style={{ color: "#E77575" }} className="mt-2">
                    Sitio es requerido
                  </b>
                )}
              </div>

              <div className="col-lg-3 form-group">
                <label htmlFor="">Tipo:</label>
                <select
                  id="tipo"
                  defaultValue={proyecto.tipo}
                  {...register("tipo", {
                    required: true,
                  })}
                  className="form-control"
                >
                  <option value="" disabled={true}>
                    Seleccione una opción
                  </option>
                  <option key={1} value={1}>
                    Proyecto
                  </option>
                  <option key={2} value={2}>
                    Noticia
                  </option>
                </select>
                {errors.tipo?.type === "required" && (
                  <b style={{ color: "#E77575" }} className="mt-2">
                    Tipo es requerido
                  </b>
                )}
              </div>

              <div className={`${!id ? "col-lg-4" : "col-lg-6"} form-group`}>
                <label htmlFor="">Descripción Español:</label>
                <textarea
                  id="descripcionEs"
                  placeholder="Escriba descripción Español"
                  cols="30"
                  rows="5"
                  {...register("descripcionEs", {
                    required: true,
                  })}
                  onChange={handleChange}
                  defaultValue={proyecto.descripcionEs}
                  className="form-control"
                ></textarea>
                {errors.descripcionEs?.type === "required" && (
                  <b style={{ color: "#E77575" }} className="mt-2">
                    Descripción Español es requerida
                  </b>
                )}
              </div>
              <div className={`${!id ? "col-lg-4" : "col-lg-6"} form-group`}>
                <label htmlFor="">Descripción Ingles:</label>
                <textarea
                  id="descripcionEn"
                  placeholder="Escriba descripción Ingles"
                  cols="30"
                  rows="5"
                  {...register("descripcionEn", {
                    required: true,
                  })}
                  defaultValue={proyecto.descripcionEn}
                  onChange={handleChange}
                  className="form-control"
                ></textarea>
                {errors.descripcionEn?.type === "required" && (
                  <b style={{ color: "#E77575" }} className="mt-2">
                    Descripción Inglés es requerida
                  </b>
                )}
              </div>
              {!id && (
                <div className="col-lg-4 form-group">
                  <label htmlFor="">Imagen:</label>
                  <input
                    type="file"
                    id="imagen"
                    {...register("imagen", {
                      required: true,
                    })}
                    className="form-control"
                    onChange={onImagenChange}
                    placeholder="Escriba título ingles"
                  />
                  {errors.imagen?.type === "required" && (
                    <b style={{ color: "#E77575" }} className="mt-2">
                      Imagen es requerida
                    </b>
                  )}
                </div>
              )}
              <div className="col-12 form-group">
                <label htmlFor="">Información proyecto en Español:</label>
                <BundledEditor
                  onInit={(evt, editor) => (editorRefEs.current = editor)}
                  // onEditorChange={(newText) => setText(newText)}
                  initialValue={edit ? detalle.contenidoEs : ""}
                  init={{
                    language: "es",
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </div>
              <div className="col-12 form-group">
                <label htmlFor="">Información proyecto en Ingles:</label>
                <BundledEditor
                  onInit={(evt, editor) => (editorRefEn.current = editor)}
                  initialValue={edit ? detalle.contenidoEn : ""}
                  // onEditorChange={(newText) => setText(newText)}
                  init={{
                    language: "es",
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "help",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card-footer text-center">
            <button
              className="btn btn-secondary btn-lg mr-2"
              onClick={() => {
                navigate("/proyectos");
              }}
            >
              <i className="fa fa-arrow-circle-left"></i> SALIR
            </button>
            <button
              className="btn btn-info btn-lg"
              onClick={handleSubmit(onSubmit.bind(this))}
            >
              <i className="fa fa-save"></i> Guardar
            </button>
          </div>
        </div>
      </LayoutPanel>
    </React.Fragment>
  );
};

export default ProyectosForm;
