import React, { Fragment, useState, useEffect } from "react";
import TablaBasic from "../../../components/tablas/TablaBasic";
import LayoutList from "../../../layout/LayoutList";
import HTTP from "../../../services/HTTP";
import { Menu, Item, contextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useNavigate } from "react-router-dom";

export default function Roles() {
  const navigate = useNavigate();
  //estado para almacenar la data console.log(event);
  const [data, setData] = useState([]);

  //rendereizar la data cada que el componente cambie
  useEffect(() => {
    consultApi();
  }, []);

  //funcion para consultar la api
  const consultApi = async () => {
    const api = await HTTP.GET("rol");
    setData(api.data);
  };

  //definimos la columnas para nuestra tabla
  const columns = [
    { text: "ID", dataField: "id" },
    { text: "NOMBRE DEL ROL", dataField: "nombre" },
    {
      text: "FECHA DE CREACION",
      dataField: "fechaCreacion",
    },
  ];

  const rowEvents = {
    onContextMenu: (e, row, rowIndex) => {
      e.preventDefault();
      contextMenu.show({
        id: "id",
        event: e,
        props: row.id,
      });
    },
  };

  const actualizarRol = ({ event, props, row }) => {
    navigate(`/roles/update/${props}`);
  };
  function IconFont(props) {
    return <i {...props} style={{ marginRight: "10px" }} />;
  }
  return (
    <Fragment>
      <LayoutList>
        <div>
          <TablaBasic
            id={"idRol"}
            evento={rowEvents}
            data={data}
            columns={columns}
          />
        </div>
        <Menu id={"id"}>
          <Item onClick={actualizarRol}>
            {" "}
            <IconFont className="fa fa-pencil" /> ACTUALIZAR
          </Item>
          <Item>DESACTIVAR</Item>
        </Menu>
      </LayoutList>
    </Fragment>
  );
}
