import configAxios from "./configAxios";
import headerAxios from "./headerAxios";
class HTTP {
  async GET(ruta, params = {}) {
    return await configAxios
      .get(ruta, { headers: headerAxios.getHeader(), params })
      .then((res) => {
        if (!res) {
          return false;
        }
        return res;
      })
      .catch((err) => {
        if (!err.res) {
          return false;
        }
        if (err.res.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        return err.res;
      });
  }
  async POST(ruta, body) {
    return await configAxios
      .post(ruta, body, { headers: headerAxios.getHeader() })
      .then((res) => {
        if (!res) {
          return false;
        }
        //si existe respuesta envia la respuesta
        return res;
      })
      .catch((error) => {
        if (!error.res) {
          return false;
        }
        if (error.res.status === 401) {
          //limpiamos la sesion
          localStorage.clear();
          window.location.href = "/login";
        }
        return error.res;
      });
  }
  async POST_FILE(ruta, body) {
    return await configAxios
      .post(ruta, body, {
        headers: {
          ...headerAxios.getHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (!res) {
          return false;
        }
        //si existe respuesta envia la respuesta
        return res;
      })
      .catch((error) => {
        if (!error.res) {
          return false;
        }
        if (error.res.status === 401) {
          //limpiamos la sesion
          localStorage.clear();
          window.location.href = "/login";
        }
        return error.res;
      });
  }
  async POST_NOT_AUTH(ruta, body) {
    return await configAxios
      .post(ruta, body)
      .then((response) => {
        if (!response) {
          return false;
        }
        //si existe respuesta envia la respuesta
        return response;
      })
      .catch((error) => {
        if (!error.response) {
          return false;
        }
        return error.response;
      });
  }

  async PUT(ruta, body) {
    return await configAxios
      .put(ruta, body, { headers: { ...headerAxios.getHeader(), "Content-Type": "application/json" } })
      .then((res) => {
        if (!res) {
          return false;
        }
        return res;
      })
      .catch((error) => {
        if (!error.res) {
          return false;
        }
        if (error.response === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        return error.res;
      });
  }

  async PATCH(ruta, body) {
    return await configAxios
      .patch(ruta, body, { headers: { ...headerAxios.getHeader(), "Content-Type": "application/json" } })
      .then((res) => {
        if (!res) {
          return false;
        }
        return res;
      })
      .catch((error) => {
        if (!error.res) {
          return false;
        }
        if (error.response === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        return error.res;
      });
  }

}


export default new HTTP();
