import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      © {year} Perkin Educational Opportunities Foundation. Todos los derechos
      reservados
    </footer>
  );
};

export default Footer;
