import React, { Fragment, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "../components/theme/Footer/Footer";
import Header from "../components/theme/header/Header";
import PublicRoute from "../routes/PublicRoute";

export default function LayoutPrincipal(props) {
  let location = useLocation();

  const [path] = useState(location.pathname);
  return (
    <Fragment>
      {path !== "/recovery" && path !== "/login" ? (
        <div className="fix-sidebar fix-header card-no-border">
          <div id="main-wrapper">
            <Routes>
              <Route path="*" element={<PublicRoute />}>
                <Route path="*" element={<Header />} />
              </Route>
            </Routes>

            <div className="page-wrapper">
              {props.children}
              <Routes>
                <Route path="*" element={<PublicRoute />}>
                  <Route path="*" element={<Footer />} />
                </Route>
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <div className="fix-sidebar fix-header card-no-border">
          <div id="main-wrapper">{props.children}</div>
        </div>
      )}
    </Fragment>
  );
}
