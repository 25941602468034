import React, { Fragment } from "react";
export default function LayoutList(props) {
  return (
    <Fragment>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">{props.nombre}</h4>
                  <div className="card-subtitle">
                    <input
                      onChange={props.busquedad}
                      value={props.valor}
                      type="text"
                      className="form-control col-lg-2"
                      placeholder="Buscar..."
                    />
                  </div>
                  <div className="table-responsive m-t-40">
                    {props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </Fragment>
  );
}
