import React, { Fragment } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";

export default function TablaBasic(props) {
  const datos = props.data;
  const columns = props.columns;
  const id = props.id;
  return (
    <Fragment>
      <BootstrapTable
        rowEvents={props.evento}
        keyField={id}
        data={datos}
        columns={columns}
      />
    </Fragment>
  );
}
