import React, { useState } from "react";
import Alert from "../../services/Alert";
import HTTP from "../../services/HTTP";
import Encriptaciones from "../../services/Encriptaciones";

const Login = () => {
  const [dataForm, setDataForm] = useState({
    username: process.env.REACT_APP_USERNAME || "",
    password: process.env.REACT_APP_PASSWORD || "",
  });

  const [loading, setLoading] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    if (loading === false) {
      setLoading(true);
      const data = {
        usuario: dataForm.username,
        password: dataForm.password,
      };
      HTTP.POST_NOT_AUTH("auth/login", data).then((result) => {
        setLoading(false);

        if (result) {
          if (result.status === 201) {
            const responseLogin = result.data;
            Encriptaciones.setSession("dataUser", responseLogin.user);
            Encriptaciones.setSession("modulos", responseLogin.modulos);
            Encriptaciones.setSession("token", responseLogin.access_token);
            window.location.href = "/";
          } else if (result.status === 401) {
            console.log(result);
            Alert.alertEmpty(
              `¡${result.data.message}!`,
              "Por favor verifique su datos e intente nuevamente",
              "info"
            );
          }
        } else {
          Alert.alertEmpty(
            "!Error al iniciar sesión!",
            "No se pudo iniciar por un problema de servidor",
            "error"
          );
        }
      });
    }
  };

  const handleChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;

    setDataForm({ ...dataForm, [idComponente]: valorComponente });
  };

  return (
    <React.Fragment>
      <section id="wrapper">
        <div
          className="login-register"
          style={{
            backgroundImage:
              "url(../assets/images/background/login-register.jpg)",
            height: "100%",
            width: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="login-box card">
            <div className="card-body">
              <form
                className="form-horizontal form-material text-center"
                id="loginform"
                onSubmit={onSubmit}
              >
                <h3 className="box-title m-b-20">Inicio de Sesión</h3>
                <div className="form-group ">
                  <div className="col-xs-12">
                    <input
                      className="form-control"
                      id="username"
                      type="text"
                      required={true}
                      value={dataForm.username}
                      placeholder="Usuario o Correo Electrónico"
                      onChange={handleChange}
                    />{" "}
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-12">
                    <input
                      className="form-control"
                      type="password"
                      required={true}
                      value={dataForm.password}
                      id="password"
                      onChange={handleChange}
                      placeholder="Contraseña"
                    />{" "}
                  </div>
                </div>
                {/* <div className="form-group row">
                  <div className="col-md-12 font-14">
                    <a
                      href="javascript:void(0)"
                      id="to-recover"
                      className="text-dark pull-right"
                    >
                      <i class="fa fa-lock m-r-5"></i> Recuperar Contraseña?
                    </a>{" "}
                  </div>
                </div> */}
                <div className="form-group text-center m-t-20">
                  <div className="col-xs-12">
                    <button
                      className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <i
                          className="fa fa-spinner fa-spin"
                          style={{ fontSize: "30px" }}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        " Iniciar Sesión"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
