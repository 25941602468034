import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import HTTP from "../../services/HTTP";

const gridStyle = { minHeight: 550, marginTop: 10 };
let timer;
const TablaFilter = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [buscar, setBuscar] = useState("");
  const getData = (filter = "") => {
    const params = {
      filter,
    };
    HTTP.GET(props.ruta, params).then((result) => {
      if (result !== false) {
        if (result.status === 200) {
          setData(result.data);
        }
      }
    });
  };
  useEffect(() => {
    const getData = (filter = "") => {
      const params = {
        filter,
      };
      HTTP.GET(props.ruta, params).then((result) => {
        if (result !== false) {
          if (result.status === 200) {
            setData(result.data);
          }
        }
      });
    };

    clearTimeout(timer);
    timer = setTimeout(() => {
      getData(buscar);
    }, 300);
  }, [buscar, props.ruta]);

  const handleChange = (e) => {
    setBuscar(e.target.value);
  };

  useImperativeHandle(ref, () => ({
    clear() {
      setBuscar("");
      getData();
    },
  }));

  return (
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="">Buscar:</label>

        <input
          type="text"
          className="form-control"
          placeholder="Escriba para buscar..."
          value={buscar}
          onChange={handleChange}
        />
      </div>
      <div className="col-lg-12">
        <ReactDataGrid
          idProperty={props.idProperty}
          style={props.style ? props.style : gridStyle}
          columns={props.columns}
          pagination
          dataSource={data}
          defaultLimit={10}
          showColumnMenuTool={false}
        />
      </div>
    </div>
  );
});

export default TablaFilter;
