import React, { Fragment } from "react";
import Seguridad from "./seguridad/Seguridad";

export default function Menu() {
  return (
    <Fragment>
      <nav className="sidebar-nav">
        <ul id="sidebarnav">
          <li className="nav-devider"></li>
          <li className="nav-small-cap">Menú Principal</li>
          <Seguridad />
        </ul>
      </nav>
    </Fragment>
  );
}
