/* eslint-disable array-callback-return */
import React, { Fragment, useState, useEffect } from "react";
import TablaBasic from "../../../components/tablas/TablaBasic";
import LayoutList from "../../../layout/LayoutList";
import HTTP from "../../../services/HTTP";
import { Menu, Item, contextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useNavigate } from "react-router-dom";

export default function Modulos() {
  const navigate = useNavigate();
  //estados para almacenar los daos de la DB
  const [data, setData] = useState([]);

  const [tabla, setTabla] = useState([]);
  //estado para busquedad en el input
  const [busquedad, setBusquedad] = useState("");

  useEffect(() => {
    //ejecutamos la funcion a la llamada a ala api
    api();
  }, []);

  const api = async () => {
    try {
      const peticion = await HTTP.GET("modulo");
      setData(peticion.data);
      setTabla(peticion.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBusquedad = (e) => {
    setBusquedad(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (busquedad) => {
    let resultado = tabla.filter((elemt) => {
      if (
        elemt.nombre.toString().toLowerCase().includes(busquedad.toLowerCase())
      ) {
        return elemt;
      }
    });

    setData(resultado);
    console.log(resultado);
  };

  const columns = [
    { text: "ID", dataField: "id" },
    { text: "NOMBRE DEL MODULO", dataField: "nombre" },
    {
      text: "FECHA DE CREACION",
      dataField: "fechaCreacion",
    },
  ];

  const rowEvents = {
    onContextMenu: (e, row, rowIndex) => {
      e.preventDefault();
      contextMenu.show({
        id: "id",
        event: e,
        props: row.id,
      });
    },
  };

  const actualizarModulo = ({ event, props, row }) => {
    navigate(`/modulos/update/${props}`);
  };
  function IconFont(props) {
    return <i {...props} style={{ marginRight: "10px" }} />;
  }
  return (
    <Fragment>
      <LayoutList
        valor={busquedad}
        busquedad={handleBusquedad}
        headerTitle="Modulos"
        nombre="Lista de modulos"
        title="Listado de modulos"
      >
        <TablaBasic
          evento={rowEvents}
          id={"id"}
          data={data}
          columns={columns}
        />

        <Menu id={"id"}>
          <Item onClick={actualizarModulo}>
            {" "}
            <IconFont className="fa fa-pencil" /> ACTUALIZAR
          </Item>
          <Item>DESACTIVAR</Item>
        </Menu>
      </LayoutList>
    </Fragment>
  );
}
