import React, { Fragment, useRef, useState } from "react";
import Alert from "../../../services/Alert";
import HTTP from "../../../services/HTTP";
import "react-contexify/dist/ReactContexify.css";
import { useNavigate } from "react-router-dom";
import LayoutPanel from "../../../layout/LayoutPanel";
import TablaFilter from "../../../components/tablas/TablaFilter";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default function Usuarios() {
  const navigate = useNavigate();
  const tablaRef = useRef();

  const [modal, setModal] = useState(false);
  const [user, setUser] = useState(null);

  const [formulario, setFormulario] = useState({});

  const actionModal = (id) => {
    setModal(!modal);
    setUser(id);
  };

  const columns = [
    { name: "id", header: "Name", minWidth: 50, defaultVisible: false },

    {
      name: "nombre",
      header: "Nombre",
      maxWidth: 300,
      defaultWidth: 150,
    },
    {
      name: "alias",
      header: "Alias",
      maxWidth: 300,
      defaultWidth: 150,
    },
    {
      name: "correo",
      header: "Correo",
      maxWidth: 300,
      defaultWidth: 200,
    },

    {
      name: "rol",
      header: "rol",
      maxWidth: 300,
      defaultWidth: 150,
      render: ({ value }) => {
        return <p className="ml-2">{value?.nombre}</p>;
      },
    },

    {
      name: "cargo",
      header: "Cargo",
      maxWidth: 300,
      defaultWidth: 300,
      render: ({ value }) => {
        return <p className="ml-2">{value?.nombre}</p>;
      },
    },
    {
      name: "option",
      header: "Opciones",
      maxWidth: 1000,
      defaultWidth: 400,
      defaultFlex: 1,
      render: ({ data, value }) => {
        return (
          <div className="text-center">
            <button
              className={"btn btn-outline-info mr-2"}
              onClick={actionModal.bind(this, data.id)}
            >
              <span>
                <i className="fa fa-pencil mr-1"></i>Cambiar Contraseña
              </span>
            </button>

            <button
              className={"btn btn-outline-info mr-2"}
              onClick={actualizarUsuarios.bind(this, data.id)}
            >
              <span>
                <i className="fa fa-pencil mr-1"></i>Actualizar
              </span>
            </button>
            <button
              className={
                data.activo
                  ? "btn btn-outline-danger mr-2"
                  : "btn btn-outline-info mr-2"
              }
              style={{ width: "130px" }}
              onClick={activacion.bind(this, data.id, data.activo)}
            >
              {data.activo ? (
                <span>
                  <i className="fa fa-close mr-1"></i>Desactivar
                </span>
              ) : (
                <span>
                  <i className="fa fa-check mr-1"></i>Activar
                </span>
              )}
            </button>
          </div>
        );
      },
    },
    {
      name: "activo",
      header: "Estatus",
      maxWidth: 100,

      render: ({ value }) => {
        if (value === true) {
          return (
            <div className="text-center">
              <span className="badge badge-info ">ACTIVO</span>
            </div>
          );
        }
        return (
          <div className="text-center">
            <span className="badge badge-danger">INACTIVO</span>
          </div>
        );
      },
    },
  ];

  const createPassword = async () => {
    setModal(!modal);
    const data = {
      password: formulario.password,
    };
    Alert.loading_reload(true, "Guardando Usuario");
    HTTP.PATCH(`usuarios/create-password/${user}`, data).then((result) => {
      Alert.loading_reload(false);

      if (result !== false) {
        if (result.status === 200) {
          tablaRef.current.clear();
          if (user) {
            Alert.toast_success("¡Cambio de contraseña correctamente!");
          }
        }
      }
    });
  };

  const activar = (id, activo) => {
    Alert.loading_reload(true);
    const data = {
      activo,
    };
    HTTP.PATCH(`usuarios/${id}`, data).then((result) => {
      Alert.loading_reload(false);

      if (result !== false) {
        if (result.status === 200) {
          tablaRef.current.clear();
          if (activo === true) {
            Alert.toast_success("¡Usuario Activo con exito!");
          } else if (activo === false) {
            Alert.toast_success("¡Usuario desactivado con exito!");
          }
        }
      }
    });
  };

  const activacion = (id, activo) => {
    if (activo === false) {
      Alert.QuestionYesNo(
        "¿Desea activar el proyecto?",
        "Administración de proyectos",
        2
      ).then((resp) => {
        if (resp) {
          activar(id, true);
        }
      });
    } else if (activo === true) {
      Alert.QuestionYesNo(
        "¿Desea desactivar el proyecto?",
        "Administración de proyectos"
      ).then((resp) => {
        if (resp) {
          activar(id, false);
        }
      });
    }
  };

  const actualizarUsuarios = (id) => {
    navigate(`/usuarios/update/${id}`);
  };

  const handleChange = (e) => {
    const idComponente = e.target.id;
    const valorComponente = e.target.value;
    setFormulario({ ...formulario, [idComponente]: valorComponente });
  };

  return (
    <Fragment>
      <LayoutPanel
        titulo="Usuarios Registrados"
        tools={
          <button
            className="btn btn-info"
            onClick={() => {
              //tablaRef.current.clear();

              navigate("/usuarios/add");
            }}
            type="button"
          >
            <i className="fa fa-plus mr-2"></i>
            Agregar Nuevo
          </button>
        }
      >
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Registros</h4>
            {/* <Button color="danger" onClick={toggle}>
              Click Me
            </Button> */}
            <Modal
              isOpen={modal}
              toggle={createPassword}
              style={{ maxWidth: "700px", width: "50%" }}
            >
              <ModalHeader toggle={createPassword}>
                Cambiar Contraseña
              </ModalHeader>
              <ModalBody>
                <form onSubmit={createPassword}>
                  <div className="row p-t-20">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="control-label text-ce">
                          Escriba contraseña
                        </label>
                        <input
                          type="text"
                          id="password"
                          className="form-control"
                          placeholder="ingrese contraseña"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-secondary btn-lg mr-2"
                  onClick={actionModal.bind(this, null)}
                >
                  Cancelar
                </button>
                <button
                  className="btn btn-info btn-lg"
                  type="submit"
                  onClick={createPassword.bind(this)}
                >
                  Guardar
                </button>
              </ModalFooter>
            </Modal>
            <div className="row">
              <div className="col-12">
                <TablaFilter
                  idProperty="id"
                  ref={tablaRef}
                  ruta="usuarios"
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </div>
      </LayoutPanel>
    </Fragment>
  );
}
