import React, { useRef } from "react";
import TablaFilter from "../../components/tablas/TablaFilter";
import LayoutPanel from "../../layout/LayoutPanel";
import Alert from "../../services/Alert";
import { useNavigate } from "react-router-dom";
import HTTP from "../../services/HTTP";

const Proyectos = () => {
  const tablaRef = useRef();
  const navigate = useNavigate();

  const publicar = (id, estado) => {
    Alert.loading_reload(true);
    const data = {
      estado,
    };
    HTTP.PATCH(`noticias/disponibilidad/${id}`, data).then((result) => {
      Alert.loading_reload(false);

      if (result !== false) {
        if (result.status === 200) {
          tablaRef.current.clear();
          if (estado === true) {
            Alert.toast_success("¡Proyecto publicado con éxito!");
          } else if (estado === false) {
            Alert.toast_success("¡Publicación proyecto anulada con éxito!");
          }
        }
      }
    });
  };
  const publicacion = (id, estado) => {
    if (estado === false) {
      Alert.QuestionYesNo(
        "¿Desea publicar proyecto en sitio web?",
        "Administración de proyectos",
        2
      ).then((resp) => {
        if (resp) {
          publicar(id, true);
        }
      });
    } else if (estado === true) {
      Alert.QuestionYesNo(
        "¿Desea anular publicación del proyecto en sitio web?",
        "Administración de proyectos"
      ).then((resp) => {
        if (resp) {
          publicar(id, false);
        }
      });
    }
  };

  const activar = (id, activo) => {
    Alert.loading_reload(true);
    const data = {
      activo,
    };
    HTTP.PATCH(`noticias/${id}`, data).then((result) => {
      Alert.loading_reload(false);

      if (result !== false) {
        if (result.status === 200) {
          tablaRef.current.clear();
          if (activo === true) {
            Alert.toast_success("¡Proyecto publicado con éxito!");
          } else if (activo === false) {
            Alert.toast_success("¡Publicación proyecto anulada con éxito!");
          }
        }
      }
    });
  };

  const activacion = (id, activo) => {
    if (activo === false) {
      Alert.QuestionYesNo(
        "¿Desea activar el proyecto?",
        "Administración de proyectos",
        2
      ).then((resp) => {
        if (resp) {
          activar(id, true);
        }
      });
    } else if (activo === true) {
      Alert.QuestionYesNo(
        "¿Desea desactivar el proyecto?",
        "Administración de proyectos"
      ).then((resp) => {
        if (resp) {
          activar(id, false);
        }
      });
    }
  };

  const actualizar = (id) => {
    navigate(`/proyectos/update/${id}`);
  };

  const columns = [
    { name: "id", header: "Name", minWidth: 50, defaultVisible: false },
    {
      name: "tituloEn",
      header: "Título Inglés",
      maxWidth: 300,
      defaultWidth: 300,
    },
    {
      name: "tituloEs",
      header: "Título Español",
      maxWidth: 300,
      defaultWidth: 300,
    },
    {
      name: "sitio",
      header: "Sitio",
      maxWidth: 200,

      render: ({ value }) => {
        if (value === 1) {
          return <p>Amún Shéa</p>;
        } else if (value === 2) {
          return <p>PEOF</p>;
        } else if (value === 3) {
          return <p>Ambos</p>;
        }
      },
    },

    {
      name: "tipo",
      header: "Tipo",
      maxWidth: 200,

      render: ({ value }) => {
        if (value === 1) {
          return <p>Proyecto</p>;
        } else if (value === 2) {
          return <p>Noticia</p>;
        }
      },
    },
    {
      name: "estado",
      header: "Publicado",
      maxWidth: 150,

      render: ({ value }) => {
        if (value === true) {
          return (
            <div className="text-center">
              <span className="badge badge-info ">SI</span>
            </div>
          );
        }
        return (
          <div className="text-center">
            <span className="badge badge-danger">NO</span>
          </div>
        );
      },
    },
    {
      name: "activo",
      header: "Estatus",
      maxWidth: 100,

      render: ({ value }) => {
        if (value === true) {
          return (
            <div className="text-center">
              <span className="badge badge-info ">ACTIVO</span>
            </div>
          );
        }
        return (
          <div className="text-center">
            <span className="badge badge-danger">INACTIVO</span>
          </div>
        );
      },
    },

    {
      name: "option",
      header: "Opciones",
      maxWidth: 1000,
      defaultWidth: 560,
      render: ({ data, value }) => {
        return (
          <div className="text-center">
            <button
              className={"btn btn-outline-info mr-2"}
              onClick={actualizar.bind(this, data.id)}
            >
              <span>
                <i className="fa fa-pencil mr-1"></i>Actualizar
              </span>
            </button>
            <button
              className={
                data.estado
                  ? "btn btn-outline-danger mr-2"
                  : "btn btn-outline-info mr-2"
              }
              style={{ width: "130px" }}
              onClick={publicacion.bind(this, data.id, data.estado)}
            >
              {data.estado ? (
                <span>
                  <i className="fa fa-cloud-download mr-1"></i>No Publicar
                </span>
              ) : (
                <span>
                  <i className="fa fa-cloud mr-1"></i>Publicar
                </span>
              )}
            </button>
            <button
              className={
                data.activo
                  ? "btn btn-outline-danger mr-2"
                  : "btn btn-outline-info mr-2"
              }
              style={{ width: "130px" }}
              onClick={activacion.bind(this, data.id, data.activo)}
            >
              {data.activo ? (
                <span>
                  <i className="fa fa-close mr-1"></i>Desactivar
                </span>
              ) : (
                <span>
                  <i className="fa fa-check mr-1"></i>Activar
                </span>
              )}
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <LayoutPanel
        titulo="Proyectos Registrados"
        tools={
          <button
            className="btn btn-info"
            onClick={() => {
              //tablaRef.current.clear();

              navigate("/proyectos/create");
            }}
            type="button"
          >
            <i className="fa fa-plus mr-2"></i>
            Agregar Nuevo
          </button>
        }
      >
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Registros</h4>
            <div className="row">
              <div className="col-12">
                <TablaFilter
                  idProperty="id"
                  ref={tablaRef}
                  ruta="noticias"
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </div>
      </LayoutPanel>
    </React.Fragment>
  );
};

export default Proyectos;
