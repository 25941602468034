import React, { Fragment, useState, useEffect } from "react";
import HTTP from "../../../services/HTTP";
import { useParams, useNavigate } from "react-router-dom";

export default function RolesAddUpdate() {
  const params = useParams();
  const navigation = useNavigate();

  useEffect(() => {
    if (params.id) {
      loadRoles(params.id);
    }
  }, [params.id]);

  //estado para el formulario
  const [formulario, setFormulario] = useState({});

  const [edit, setEdit] = useState(false);

  //funcion para guardar un nuevo roles
  const handleSubmit = async (e) => {
    try {
      if (edit) {
        e.preventDefault();
        hanldeUpdate();
      } else {
        e.preventDefault();
        const newRol = {
          nombre: formulario.rol,
        };
        const postRol = await HTTP.POST("rol", newRol);
        if (postRol.status === 200) {
          navigation("roles");
        }
      }
    } catch (error) {
      return error.message;
    }
  };

  const hanldeUpdate = async () => {
    try {
      const updateRol = {
        nombre: formulario.rol,
      };
      const update = await HTTP.PUT(`rol/${params.id}`, updateRol);
      if (update.status === 200) {
        console.log("update successful");
      }
    } catch (error) {
      return error.message;
    }
  };

  const loadRoles = async (id) => {
    try {
      const loadrol = await HTTP.GET(`rol/${id}`);
      console.log(loadrol.data);
      setFormulario(loadrol.data);
      setEdit(true);
    } catch (error) {
      return error.message;
    }
  };

  //funcion para leer el contenido del de los text del form
  const handleChanges = (e) => {
    setFormulario({
      ...formulario,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-outline-info">
            <div className="card-header">
              <h4 className="m-b-0 text-white">Formulario para roles</h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-body">
                  <h3 className="card-title">Informacion del Roles</h3>
                  <hr />
                  <div className="row p-t-20">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="control-label">Nombre del Rol:</label>
                        <input
                          type="text"
                          id="rol"
                          className="form-control"
                          placeholder="Nombre del rol..."
                          onChange={handleChanges}
                          defaultValue={formulario.rol}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions">
                  <button type="submit" className="btn btn-success">
                    <i className="fa fa-check"></i> Save
                  </button>
                  <button type="button" className="btn btn-inverse">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
